<template>
  <div id="admin-doctor-details">
    <div class="title">Profilim</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Doktorlar</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group"></div>
    </div>

    <div class="main">
      <div class="profile">
        <div class="profile-top">
          <div class="profile-photo">
            <img src="../../assets/img/profile-photo.png" alt="" />
          </div>
          <div class="profile-name">Dr. Betül Korkmaz</div>
        </div>
        <div class="profile-bottom">
          <div class="item">
            <i class="icon-location"></i><span>{{ user.InvoiceAddress.District }}, {{ user.InvoiceAddress.City }}</span>
          </div>
          <div class="item" v-if="user.Email != null">
            <i class="icon-message"></i><span>{{ user.Email }}</span>
          </div>
          <div class="item">
            <i class="icon-mobile"></i><span>{{ user.PhoneNumber | VMask("+## ### ### ## ##") }}</span>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="address">
          <p>Adres</p>
          <div class="info">
            <label class="data1">Adres</label>
            <label class="data2">1. Mah. 2. Cad. No:3</label>
          </div>
          <div class="info">
            <label class="data1">İl</label>
            <label class="data2">İstanbul</label>
          </div>
          <div class="info">
            <label class="data1">İlçe</label>
            <label class="data2">Kadıköy</label>
          </div>
          <div class="info">
            <label class="data1">Posta Kodu</label>
            <label class="data2">34000</label>
          </div>
        </div>
        <div class="documents-box">
          <div class="title">
            <div>Belgelerim</div>
            <a href="">Tümünü Gör</a>
          </div>

          <div class="profile-slider owl-theme owl-carousel">
            <div class="slider-item">
              <div class="doc-card">
                <div class="doc-information">
                  <div class="top-information">
                    <div class="checked"><i class="icon-checked"></i></div>
                    <div class="dots">
                      <el-dropdown>
                        <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item>
                            <span @click="generatePresignedUrl(scope.row)" style="display: block; width: 100%">Dosyayı Görüntüle</span>
                          </el-dropdown-item>
                          <el-dropdown-item divided><span class="tex-danger" style="display: block; width: 100%" @click="deleteFile(scope.row)">Sil</span></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>

                  <div class="pdf">
                    <img src="../../assets/img/PDF.png" alt="" />
                  </div>
                  <div class="pdf-bottom">Diploma</div>
                  <div class="bottom-information">
                    <div class="file-size">8 GB</div>
                    <div class="date">12.07.2022</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slider-item">
              <div class="doc-card">
                <div class="doc-information">
                  <div class="top-information">
                    <div class="checked"><i class="icon-checked"></i></div>
                    <div class="dots">
                      <el-dropdown>
                        <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item>
                            <span @click="generatePresignedUrl(scope.row)" style="display: block; width: 100%">Dosyayı Görüntüle</span>
                          </el-dropdown-item>
                          <el-dropdown-item divided><span class="tex-danger" style="display: block; width: 100%" @click="deleteFile(scope.row)">Sil</span></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>

                  <div class="pdf">
                    <img src="../../assets/img/PDF.png" alt="" />
                  </div>
                  <div class="pdf-bottom">Diploma</div>
                  <div class="bottom-information">
                    <div class="file-size">8 GB</div>
                    <div class="date">12.07.2022</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slider-item">
              <div class="doc-card">
                <div class="doc-information">
                  <div class="top-information">
                    <div class="checked"><i class="icon-checked"></i></div>
                    <div class="dots">
                      <el-dropdown>
                        <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item>
                            <span @click="generatePresignedUrl(scope.row)" style="display: block; width: 100%">Dosyayı Görüntüle</span>
                          </el-dropdown-item>
                          <el-dropdown-item divided><span class="tex-danger" style="display: block; width: 100%" @click="deleteFile(scope.row)">Sil</span></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>

                  <div class="pdf">
                    <img src="../../assets/img/PDF.png" alt="" />
                  </div>
                  <div class="pdf-bottom">Diploma</div>
                  <div class="bottom-information">
                    <div class="file-size">8 GB</div>
                    <div class="date">12.07.2022</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="end">
        <div class="past">
          <div class="top">
            <div class="head-tag">İşlem Geçmişi</div>
            <div class="button">
              <el-button
                ><el-dropdown>
                  <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <span @click="generatePresignedUrl(scope.row)" style="display: block; width: 100%">Dosyayı Görüntüle</span>
                    </el-dropdown-item>
                    <el-dropdown-item divided><span class="tex-danger" style="display: block; width: 100%" @click="deleteFile(scope.row)">Sil</span></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown></el-button
              >
            </div>
          </div>
          <div class="content-all">
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
            <div class="part">
              <div class="left">
                <div class="img"><i class="icon-bill"></i></div>
                <div class="name-date">
                  <div class="name">Kaan Alp</div>
                  <div class="date">01.05.2022</div>
                </div>
              </div>
              <div class="right">
                <div class="hour">10:25</div>
                <div class="details"><a href="">Detay</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="profileSettings" :width="screenWidth > 991 ? '40%' : '90%'" class="profile-settings-dialog" :close-on-click-modal="false">
      <div class="dialog">
        <div class="dialog-left">
          <div class="dialog-left-top">
            <div class="image">
              <i class="icon-camera-2"></i>
              <div>Fotoğraf Yükle</div>
            </div>
          </div>
          <div class="dialog-left-bottom">
            <ul>
              <li :class="{ active: activeTab == 0 ? true : false }" @click="activeTab = 0">
                <i class="icon-details"></i>
                <div>Kişisel Bilgilerim</div>
              </li>
              <li :class="{ active: activeTab == 1 ? true : false }" @click="activeTab = 1">
                <i class="icon-truck"></i>
                <div>Adresim</div>
              </li>
              <li :class="{ active: activeTab == 2 ? true : false }" @click="activeTab = 2">
                <i style="background: #44566c" class="icon-folder"></i>
                <div>Belgelerim</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 0">
          <div class="container">
            <div class="title2">Kişisel Bilgilerim</div>
            <div>İsim:*</div>
            <el-input v-model="profileSettingsForm.name"></el-input>

            <div>Soyad:*</div>
            <el-input v-model="profileSettingsForm.surname"></el-input>

            <div>Cep Telefonu:*</div>
            <el-input v-model="profileSettingsForm.phoneNumber"></el-input>

            <div>E-Posta:*</div>
            <el-input v-model="profileSettingsForm.email"></el-input>

            <div>Doğum Tarihi (İsteğe Bağlı):</div>
            <div class="dt-container">
              <el-select v-model="profileSettingsForm.day" placeholder="Gün">
                <el-option v-for="item in day" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
              <el-select v-model="profileSettingsForm.month" placeholder="Ay">
                <el-option v-for="item in month" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
              <el-select v-model="profileSettingsForm.year" placeholder="Yıl">
                <el-option v-for="item in year" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>

            <div>Cinsiyet (İsteğe Bağlı):</div>
            <el-select v-model="value" placeholder="Seçiniz..">
              <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <el-button class="save-button"><i class="icon-refresh"></i>Kaydet</el-button>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 1">
          <div class="container">
            <div class="title2">Adresim</div>
            <div>Ülke:*</div>
            <el-input v-model="profileSettingsForm.country"></el-input>

            <div>İl:*</div>
            <el-input v-model="profileSettingsForm.city"></el-input>

            <div>İlçe:*</div>
            <el-input v-model="profileSettingsForm.district"></el-input>

            <div>Açık Adres:*</div>
            <el-input v-model="profileSettingsForm.address"></el-input>

            <div>Posta Kodu:*</div>
            <el-input v-model="profileSettingsForm.postcode"></el-input>

            <div>Telefon:*</div>
            <el-input v-model="profileSettingsForm.phone"></el-input>

            <el-button class="save-button"><i class="icon-refresh"></i>Kaydet</el-button>
          </div>
        </div>
        <div class="dialog-right" v-if="activeTab == 2">
          <div class="container">
            <div class="title2">Belgelerim</div>
            <div class="doc-upload-top">
              <el-upload class="upload-demo" drag :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" multiple>
                <div class="el-upload__text"><img src="../../assets/img/upload.png" alt="" />Drag and Drop or <em>Browse</em> to upload</div>
              </el-upload>
            </div>

            <div class="doc-upload-bottom">
              <div class="file">
                <img src="../../assets/img/PDF2.png" alt="" />
                <div>Blog Yazısı.pdf</div>
                <i class="icon-delete"></i>
              </div>
              <div class="file">
                <img src="../../assets/img/PDF2.png" alt="" />
                <div>Blog Yazısı.pdf</div>
                <i class="icon-delete"></i>
              </div>
            </div>

            <el-button class="save-button"><i class="icon-refresh"></i>Kaydet</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: 0,
      activeTab: 0,
      profileSettingsForm: {
        name: "",
        surname: "",
        phoneNumber: "",
        email: "",
        month: "",
        day: "",
        year: "",
        country: "",
        city: "",
        district: "",
        address: "",
        postcode: "",
        phone: "",
        cardOwner: "",
        cardNumber: "",
        expirationDate: "",
        cvvCode: "",
      },
      gender: [
        {
          value: "Kadın",
          label: "Kadın",
        },
        {
          value: "Erkek",
          label: "Erkek",
        },
        {
          value: "Belirtmek İstemiyorum",
          label: "Belirtmek İstemiyorum",
        },
      ],
      day: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
      ],
      month: [
        {
          value: "Ocak",
          label: "Ocak",
        },
        {
          value: "Şubat",
          label: "Şubat",
        },
        {
          value: "Mart",
          label: "Mart",
        },
        {
          value: "Nisan",
          label: "Nisan",
        },
        {
          value: "Mayıs",
          label: "Mayıs",
        },
        {
          value: "Haziran",
          label: "Haziran",
        },
        {
          value: "Temmuz",
          label: "Temmuz",
        },
        {
          value: "Ağustos",
          label: "Ağustos",
        },
        {
          value: "Eylül",
          label: "Eylül",
        },
        {
          value: "Ekim",
          label: "Ekim",
        },
        {
          value: "Kasım",
          label: "Kasım",
        },
        {
          value: "Aralık",
          label: "Aralık",
        },
      ],
      year: [
        {
          value: "1990",
          label: "1990",
        },
        {
          value: "1991",
          label: "1991",
        },
        {
          value: "1992",
          label: "1992",
        },
      ],
      profileSettings: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  beforeMount() {
    window.jQuery(document).ready(function () {
      window.jQuery(".profile-slider").owlCarousel({
        items: 1,
        margin: 30,
      });
    });
  },
};
</script>

<style lang="less">
#admin-doctor-details {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;
    span {
      color: #8697a8;
    }
  }
  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;
        i {
          background: #44566c;
        }
      }
    }
  }

  .main {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 991px) {
      flex-direction: column;

      .profile,
      .middle {
        width: 100% !important;
      }
    }

    .profile-name {
      font-weight: 500;
      font-size: 30px;
      color: #44566c;
    }

    .profile {
      height: 566px;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }

    .profile-top {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
    }

    .profile-bottom {
      background-color: #f8fafb;
      padding: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0.4rem;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;

        & > :last-child {
          min-width: 150px;
        }
      }

      i {
        color: #8697ab;
      }
    }

    .profile-photo {
      width: 220px;
      height: 220px;
      border-radius: 40px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .edit {
      background: #234A98;
      border-radius: 50px;
      box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

      span {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.4rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
        border: none;
      }
    }
  }

  .middle {
    width: 37.75%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .address {
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 1rem;

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #44566c;
      }

      .info {
        display: flex;
        justify-content: space-between;
      }
    }

    .icon-task {
      width: 22px;
      height: 22px;
      background-color: #44566c;
      float: right;
    }

    .data1 {
      font-weight: 400;
      font-size: 15px;
      color: #8697a8;
    }

    .data2 {
      color: #44566c;
      font-weight: 500;
      font-size: 15px;
    }

    .documents-box {
      height: 299px;
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      padding: 25px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        a {
          font-weight: 400;
          font-size: 15px;
          text-decoration: none;
          color: #234A98;
        }

        div {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #44566c;
        }
      }
    }
    .doc-card {
      overflow: hidden;
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      box-shadow: 0px 4px 32px rgba(169, 194, 209, 0.05), 0px 2px 16px rgba(169, 194, 209, 0.05);
    }

    .doc-information {
      display: flex;
      flex-direction: column;
      padding: 15px;
      width: 100%;

      .pdf {
        align-self: center;
        padding-bottom: 5px;
      }

      .pdf-bottom {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;
        align-self: center;
      }
      .top-information {
        display: flex;
        justify-content: space-between;

        .icon-dots {
          transform: rotate(-90deg);
        }
      }
      .bottom-information {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #8697a8;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .end {
    width: 37.75%;
    display: flex;
    gap: 30px;

    .past {
      width: 100%;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      background-color: #fff;
      border-radius: 15px;

      height: 100%;
      padding-bottom: 40px;
      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 10px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: white;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #f1f1f1;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #8697a8;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 30px 40px 30px;
        .head-tag {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #44566c;
        }
        .el-button {
          border: none;
          padding: 0;
          &:hover,
          &:visited {
            background-color: white;
          }
        }
      }
      .content-all {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: 380px;

        .part {
          padding: 15px 30px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #eaedf0;
          &:last-child {
            border-bottom: none;
          }
          align-items: center;
          height: 69px;
          .left {
            display: flex;
            align-items: center;
            gap: 4px;
            .img {
              width: 46px;
              height: 42px;
            }
            .name-date {
              display: flex;
              flex-direction: column;
              gap: 4px;
              .name {
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #44566c;
              }
              .date {
                font-weight: 500;
                font-size: 13px;
                line-height: 15px;
                color: #8697a8;
              }
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .hour {
              font-weight: 500;
              font-size: 15px;
              line-height: 18px;
              color: #44566c;
            }
            .details {
              a {
                font-weight: 500;
                font-size: 13px;
                line-height: 15px;
                color: #8697a8;
              }
            }
          }
        }
      }
    }
  }

  .owl-theme .owl-dots .owl-dot:focus span {
    background: #234A98;
  }

  .owl-theme .owl-dots span {
    width: 7px;
    height: 7px;
  }
  .dialog {
    display: flex;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      .dialog-left {
        width: 100% !important;
        .dialog-left-bottom {
          ul {
            margin: 0;
            flex-direction: row;
            li {
              flex-direction: column;
              height: auto;
            }
          }
        }
      }
      .dialog-right {
        width: 100% !important;
      }
    }
    .dialog-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      background-color: #f8fafb;
      .dialog-left-top {
        padding-top: 2rem;
        display: flex;
        align-self: center;
        margin-bottom: 55px;
        .image {
          width: 170px;
          height: 170px;
          background: #44566c;
          opacity: 0.6;
          border-radius: 40px;
          box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
          display: flex;
          flex-direction: column;
          justify-content: center;

          div,
          i {
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            align-self: center;
          }
        }
      }
      .dialog-left-bottom {
        background: #f8fafb;
        padding-left: 8px;

        ul {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding: 0;
        }

        li {
          cursor: pointer;
          display: flex;
          list-style: none;
          align-items: center;
          padding: 10px;
          gap: 10px;
          width: 100%;
          &.active {
            background-color: white;
          }

          height: 56px;

          div {
            font-weight: 500;
            font-size: 17px;
            color: #44566c;
          }
        }
      }
    }
    .dialog-right {
      padding: 1rem;
      width: 60%;
      display: flex;
      flex-direction: column;

      .container {
        width: 100% !important;
        .el-input {
          input {
            width: 100%;
          }
        }
        div {
          i {
            margin-left: 5px;
          }
        }

        .dt-container {
          display: flex;
          gap: 15px;

          .el-select {
            width: 33.333%;

            input {
              width: 100%;
            }
          }
        }
        .doc-upload-top {
          display: flex;
          justify-content: center;
          width: 100%;

          .upload-demo {
            width: 100%;
            .el-upload {
              width: 100%;
            }
            .el-upload-dragger {
              display: flex;
              justify-content: center;
              background: #eaedf0;
              width: 100%;
              height: 100px;

              .el-upload__text {
                width: auto;
                height: 30px;
                align-self: center;
                img {
                  padding-right: 5px;
                }
                em {
                  color: #234A98;
                }
              }
            }
          }
        }
        .doc-upload-bottom {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-top: 30px;
          margin-bottom: 15px;

          .file {
            display: flex;
            gap: 20px;
            padding: 15px;
            background: #f8fafb;
            height: 72px;
            img {
              width: 31px;
              height: 40px;
            }
            i {
              margin-left: auto;
            }
          }
        }
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-self: center;

      input,
      select {
        background-color: #f8fafb;
        width: 280px;
        height: 42.22px;
        border: none;
      }

      div {
        color: #44566c;
        font-weight: 400;
        font-size: 15px;
      }

      .title2 {
        font-weight: 700;
        font-size: 30px;
        color: #44566c;
        padding-top: 15px;
        margin-bottom: 15px;
      }

      .save-button {
        background-color: #234A98;
        color: #fff;
        width: 116px;
        height: 40px;
        font-weight: 500;
        font-size: 15px;
        align-self: flex-end;
        margin-top: 10px;

        i {
          float: left;
        }
      }
    }
  }
}
</style>
